<template>
  <div class="pageV">
    <div class="header">
      <van-nav-bar :title="$t('TransactionDetails')" left-arrow @click-left="$router.go(-1)" />
    </div>
    <div style="padding-top: 50px;background-color: #fff;">
      <img v-if="orderDetails.status == 1" src="../../assets/img/order1.svg" class="image1" />
      <img v-if="orderDetails.status == 2" src="../../assets/img/order2.svg" class="image1" />
      <img v-if="orderDetails.status == 3" src="../../assets/img/order3.svg" class="image1" />
      <img v-if="orderDetails.status == 4" src="../../assets/img/order4.svg" class="image1" />
      <div v-if="orderDetails.status == 0" class="text1">
        {{ $t("Matching") }}
      </div>
      <div v-if="orderDetails.status == 1" class="text1">
        {{ $t("ToBePaid") }}
      </div>
      <div v-if="orderDetails.status == 2" class="text1">
        {{ $t("ToBeReviewed") }}
      </div>
      <div v-if="orderDetails.status == 3" class="text1">
        {{ $t("RechargeSuccessful") }}
      </div>
      <div v-if="orderDetails.status == 4" class="text1">
        {{ $t("RechargeFailed") }}
      </div>

      <div class="itemV" style="margin-top: 50px;">
        <span class="itemTv1">{{ $t("fbuy.orderno") }}</span>
        <span class="itemTv2">{{ orderDetails.order_sn }}</span>
        <img class="itemIv" src="../../assets/img/copy.svg" @click.prevent="onCopyClick()"
          :data-clipboard-text="orderDetails.order_sn" />
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("timetime") }}</span>
        <span class="itemTv2">{{ orderDetails.create_time | dateformat }}</span>
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("money") }}</span>
        <span class="itemTv2">{{ orderDetails.price }}</span>
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("usdtMoney") }}</span>
        <span class="itemTv2">{{ orderDetails.real_price }}</span>
      </div>
      <div class="itemV">
        <span class="itemTv1">{{ $t("Remarks") }}</span>
        <span class="itemTv2">{{ orderDetails.remark }}</span>
      </div>
      <div class="itemV2">
        <span class="itemTv1">{{ $t("TransactionScreenshot") }}</span>
        <img class="itemIv2" :src="orderDetails.img" @click.prevent="previewimg(orderDetails.img)" />
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from 'vant'
import Clipboard from "clipboard";
export default {
  data() {
    return {
      id: "",
      orderDetails: "",
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.onLoad();
  },
  methods: {
    async onLoad() {
      const { data } = await this.$http.get(
        "/home/user/rechargeCInfo?id=" + this.id
      );
      if (data) {
        if (data.code === 200) {
          this.orderDetails = data.data;
        }
      }
    },
    onCopyClick() {
      const clipboard = new Clipboard(".itemIv");
      clipboard.on("success", (e) => {
        this.$toast.success(this.$t("common.success"));
      });
      clipboard.on("error", (e) => {
        console.log("------", JSON.stringify(e));
      });
    },
    previewimg(url) {
      ImagePreview([url])
    },
  },
};
</script>
<style lang="less" scoped>
.pageV {
  min-height: 100vh;
  height: auto;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
}

.image1 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 80px;
}

.text1 {
  color: #4d5260;
  font-weight: 500;
  font-size: 14px;
  margin-top: 15px;
}

.itemV {
  display: flex;
  align-items: center;
  border-top: 0.1px solid #f7f8fa;
  height: 45px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.itemV2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 0.1px solid #f7f8fa;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
}

.itemTv1 {
  color: #9097a7;
  font-size: 15px;
}

.itemTv2 {
  color: #4d5260;
  font-size: 15px;
  flex: 1;
  text-align: end;
}

.itemIv {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.itemIv2 {
  width: 100px;
  height: 100px;
}

.addTv {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 3.18rem;
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 700;
  position: fixed;
  right: 1rem;
  top: 60%;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
